<template>
  <NuxtLink
    :to="to"
    class="relative inline-block rounded-full shadow-[0px_0px_44px_#12E2FF]"
    @click="onClick"
  >
    <svg width="123" height="31" viewBox="0 0 123 31" fill="none">
      <ellipse cx="16.1631" cy="15.869" rx="3.9158" ry="4.07408" fill="#12E2FF" />
      <path
        d="M14.9172 1.26561H15.1101V8.89654C11.8446 9.42349 9.36517 12.3553 9.36517 15.8691C9.36517 19.383 11.8446 22.3148 15.1101 22.8417V30.4727H14.9172C7.18843 30.4727 0.89647 23.9494 0.89647 15.8691C0.89647 7.78888 7.18844 1.26561 14.9172 1.26561ZM108.083 30.4727H17.3926V22.8108C20.5708 22.2083 22.9612 19.3196 22.9612 15.8691C22.9612 12.4187 20.5708 9.52995 17.3926 8.9275V1.26561H108.083C115.812 1.26561 122.104 7.78888 122.104 15.8691C122.104 23.9494 115.812 30.4727 108.083 30.4727Z"
        fill="url(#paint0_linear_1290_98828)"
        fill-opacity="0.26"
        stroke="#12E2FF"
        stroke-width="0.79294"
      />

      <defs>
        <linearGradient
          id="paint0_linear_1290_98828"
          x1="5.60686"
          y1="17.5002"
          x2="119.652"
          y2="17.8808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#12E2FF" />
          <stop offset="0.494792" stop-color="#12E2FF" stop-opacity="0.6" />
          <stop offset="0.989583" stop-color="#12E2FF" />
        </linearGradient>
      </defs>
    </svg>

    <span
      class="absolute left-[32px] top-[5px] block whitespace-nowrap text-sm font-semibold text-cyan"
    >
      {{ label }}</span
    >
  </NuxtLink>
</template>

<script setup lang="ts">
interface Props {
  disabled?: boolean
  label?: string | null
  to: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false
})

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void
}>()

function onClick(event: MouseEvent) {
  if (props.disabled) {
    event.preventDefault()
    event.stopPropagation()
  }
  emit('click', event)
}
</script>
